export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/admin": [8,[2,3]],
		"/(app)/admin/evaluations": [9,[2,3]],
		"/(app)/admin/settings": [10,[2,3]],
		"/auth": [31],
		"/(app)/c/[id]": [11,[2]],
		"/error": [32],
		"/gozoom/faq": [33,[6]],
		"/gozoom/privacy-policy": [34,[6]],
		"/gozoom/support": [35,[6]],
		"/gozoom/terms": [36,[6]],
		"/new-user": [37],
		"/(app)/playground": [12,[2,4]],
		"/(app)/playground/completions": [13,[2,4]],
		"/(app)/playground/notes": [14,[2,4]],
		"/s/[id]": [38],
		"/upgrade": [39],
		"/watch": [40],
		"/(app)/workspace": [15,[2,5]],
		"/(app)/workspace/functions": [16,[2,5]],
		"/(app)/workspace/functions/create": [17,[2,5]],
		"/(app)/workspace/functions/edit": [18,[2,5]],
		"/(app)/workspace/knowledge": [19,[2,5]],
		"/(app)/workspace/knowledge/create": [21,[2,5]],
		"/(app)/workspace/knowledge/[id]": [20,[2,5]],
		"/(app)/workspace/models": [22,[2,5]],
		"/(app)/workspace/models/create": [23,[2,5]],
		"/(app)/workspace/models/edit": [24,[2,5]],
		"/(app)/workspace/prompts": [25,[2,5]],
		"/(app)/workspace/prompts/create": [26,[2,5]],
		"/(app)/workspace/prompts/edit": [27,[2,5]],
		"/(app)/workspace/tools": [28,[2,5]],
		"/(app)/workspace/tools/create": [29,[2,5]],
		"/(app)/workspace/tools/edit": [30,[2,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';